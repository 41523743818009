@import "../../scss/mixin.scss";

.build-one {
  display: flex;
  padding: 6rem 3rem;
  gap: 1.5rem;
  @include mobile {
    flex-direction: column;
    padding: 3rem 1.5rem;
  }
  @include tablet {
    flex-direction: column;
    padding: 3rem 1.5rem;
  }
  .motion-wrapper {
    width: 50%;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      margin-top: 50px;
      width: 100%;
    }
  }

  .copy-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    @include mobile {
      width: 100%;
    }

    @include tablet {
      width: 100%;
    }

    h5 {
      padding-bottom: 1.5rem;
      @include mobile {
        padding-bottom: 1rem;
      }
    }
  }
}

.build-two {
}

.build-three {
  padding: 6rem 3rem;
  background-color: $olive;
  color: $beige;
  @include mobile {
    padding: 3rem 1.5rem;
  }
  h5 {
    padding-bottom: 3rem;
  }
  .anim-block {
    height: 32px;
    position: relative;
    top: 0;
    content: " ";
    background-color: $beige;
    width: 100%;
  }
  .c-b {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1.5rem;
    @include mobile {
      flex-direction: column;
      gap: 3rem;
    }
    .c {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @include mobile {
        align-items: flex-start;
        width: 100%;
      }
      h4 {
        width: 100%;
        padding: 1.5rem 0;
        @include mobile {
          padding: 1rem 0;
        }
      }
      p {
        width: 50%;
        @include mobile {
          width: 100%;
        }
      }
    }
    .b {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include mobile {
        align-items: flex-start;
        width: 100%;
      }
      h4 {
        width: 100%;
        padding: 1.5rem 0;
        @include mobile {
          padding: 1rem 0;
        }
      }
      p {
        width: 100%;
      }
    }
  }
  .h-f-e {
    display: flex;
    gap: 1.5rem;
    padding: 3rem 0;
    @include mobile {
      flex-direction: column;
      gap: 3rem;
    }
    h4 {
      padding: 1.5rem 0;
      @include mobile {
        padding: 1rem 0;
      }
    }
    .h {
      width: 25%;
      @include mobile {
        width: 100%;
      }
    }
    .f {
      width: 25%;
      @include mobile {
        width: 100%;
      }
    }
    .e {
      width: 50%;
      @include mobile {
        width: 100%;
      }
    }
  }
}

.build-four {
  padding: 6rem 3rem;
  display: flex;
  gap: 1.5rem;
  @include mobile {
    flex-direction: column;
    padding: 3rem 1.5rem;
  }
  .motion-wrapper {
    width: 50%;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
  }
  p {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }
}

.build-five {
  padding: 3rem 3rem;
  @include mobile {
    padding: 0rem 1.5rem;
  }
  p {
    padding: 0 0 1rem;
  }
  .back-img {
    background-image: url("../../../public/images/building/gallery/7.jpg");
    height: 90vh;
    background-size: cover;
    background-position: center;
    @include mobile {
      height: 30vh;
    }
  }
}

.build-six {
  padding: 6rem 3rem;
  display: flex;
  gap: 1.5rem;
  @include mobile {
    flex-direction: column;
    padding: 0 1.5rem;
  }
  .motion-wrapper {
    width: 50%;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
  }
  p {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }
}

.build-seven {
  padding: 3rem 3rem;
  @include mobile {
    padding: 1.5rem 1.5rem 0;
  }
  .back-img {
     background-image: url("../../../public/images/building/gallery/8.jpg");
    height: 90vh;
    background-size: cover;
    background-position: center;
    @include mobile {
      height: 30vh;
    }
  }
  p {
    padding: 1rem 0 0;
  }
}

.build-eight {
  padding: 6rem 3rem;
  display: flex;
  gap: 1.5rem;
  @include mobile {
    padding: 3rem 1.5rem;
    flex-direction: column;
  }
  .motion-wrapper {
    width: 50%;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
  }
  p {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }
}

.build-nine {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  height: 100vh;
  padding: 3rem;
  @include mobile {
    height: fit-content;
    padding: 1.5rem;
    flex-direction: column-reverse;
  }
  @include tablet {
    height: fit-content;
    padding: 1.5rem;
    flex-direction: column-reverse;
  }

  @include small-desktop {
    height: fit-content;
    padding: 1.5rem;
    flex-direction: column-reverse;
    height: 75vw;
  }
  .collage {
    display: flex;
    gap: 1.5rem;
    width: 100%;
    height: 75vw;
    @include mobile {
      gap: 0.5rem;
      flex-direction: column;
      height: fit-content;
    }
    @include tablet {
      gap: 0.5rem;
      flex-direction: column;
      height: fit-content;
    }
    @include small-desktop {
      height: 75vw;
    }
    .main-img {
      width: 70vw;
      background-image: url("../../../public/images/building/collage/1.jpg");
      background-size: cover;
      
      @include mobile {
        width: 100%;
        aspect-ratio: 1/1;
        height: fit-content;
        background-size: 300%;
      }
      @include tablet {
        width: 100%;
        aspect-ratio: 1/1;
        height: fit-content;
        background-size: 350%;
      }
      @include small-desktop {
        width: 75vw;
        background-size: 400%;
      }
    }
    .other-wrapper {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      @include mobile {
        gap: 0.5rem;
        flex-direction: row;
        width: 100%;
      }
      @include tablet {
        gap: 0.5rem;
        flex-direction: row;
        width: 100%;
      }
      @include small-desktop {
        width: 25vw;
      }
      .other-img {
        height: 50%;
        width: 100%;
        /* background-size: cover; */
        background-size: cover;
      
        &:nth-child(2) {
          background-size: cover;
        
        }
        @include mobile {
          width: 50%;
          height: unset;
          aspect-ratio: 1/1;
        }
        @include tablet {
          width: 50%;
          height: unset;
          aspect-ratio: 1/1;
        }
        &.top {
          background-image: url("../../../public/images/building/collage/2.jpg");
        }
        &.bottom {
          background-image: url("../../../public/images/building/collage/3.jpg");
        }
      }
    }
  }
}

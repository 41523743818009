@import "../../scss/mixin.scss";

.loc-one {
  padding: 120px 3rem;
  gap: 32px;
  display: flex;

  @include mobile {
    flex-direction: column;
    padding: 3rem 1.5rem;
  }

  @include tablet {
    flex-direction: column;
  }

  .motion-wrapper {
    width: 50%;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
  }
  .copy-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
  }
}

.img-group {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

.loc-two {
  display: flex;
  padding: 3rem;
  gap: 32px;
  width: 100%;
  @include mobile {
    flex-direction: column;
    padding: 1.5rem;
  }
  @include tablet {
    flex-direction: column;
  }
  .lg-img {
    background-image: url("../../../public/images/location/location3.jpg");
    background-size: cover;
    height: 90vh;
    width: 65%;
    @include mobile {
      width: 100%;
      height: 90vw;
    }
    @include tablet {
      width: 100%;
      height: 90vw;
    }
  }
  .sm-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 35%;
    height: 90vh;
    @include mobile {
      width: 100%;
      height: 150vw;
    }

    @include tablet {
      width: 100%;
      height: 150vw;
    }
    .sm-img {
      background-image: url("../../../public/images/location/location2.jpg");
      background-size: cover;
      height: 50%;
      @include mobile {
        width: 100vw;
        height: 100vw;
      }
      @include tablet {
        width: 100vw;
        height: 100vw;
      }
    }
    .sm-img2 {
      background-image: url("../../../public/images/location/location1.jpg");
      background-size: cover;
      height: 50%;
      @include mobile {
        width: 100vw;
        height: 100vw;
      }
      @include tablet {
        width: 100vw;
        height: 100vw;
      }
    }
  }
  &.narrow {
    padding: 0 3rem 3rem 3rem;
  }
}

.loc-three {
  background-color: #dfd8ca;
  padding: 120px 3rem;
  display: flex;
  flex-direction: column;
  @include small-desktop {
    padding: 120px 3rem 0;
  }
  @include mobile{
    padding: 1.5rem;
  }
  .copy-section {
    display: flex;
    gap: 32px;
    padding-bottom: 72px;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
    .motion-wrapper {
      width: 50%;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
    }
    .copy-wrapper {
      width: 50%;
      display: flex;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
    }
  }
  .animated-section {
    display: flex;
    gap: 32px;
    align-items: center;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
    @include small-desktop {
      flex-direction: column;
      gap: 0;
    }
    .venn-wrapper {
      width: 50%;
      position: relative;
      height: 700px;

      @include mobile {
        width: 100%;
        transform: scale(0.6);
        height: 400px;
        margin-bottom: 50px;
      }

      @include tablet {
        width: 100%;
      }

      .circle {
        width: 350px;
        height: 350px;
        @include mobile{
          width: 300px;
          height: 300px;
        }
        border-radius: 50%;
        transform-origin: 50% 100%;
        top: 0;
        left: calc(50% - 175px);
        @include mobile{
          left: calc(50% - 150px);
        }
        &:first-child {
          background-color: $green;
          position: absolute;
          top: 0;
          display: flex;
          justify-content: center;
          .copy-wrapper {
            width: 189px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 32px;
            color: $beige;
          }
          img {
            z-index: 10;
            position: absolute;
            bottom: 32px;
          }
        }
        &:nth-child(2) {
          background-color: $pink;
          position: absolute;
          top: 0;
          mix-blend-mode: multiply;
          // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          padding: 48px 32px;
          @include mobile{
            padding: 35px;
          }
          .copy-wrapper {
            width: 200px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 16px;
            color: $beige;
            transform: rotate(-90deg);
            z-index: 10;
            isolation: isolate;
            
            h2 {
              isolation: isolate;
              position: relative;
              z-index: 2;
            }
          }
        }
        &:nth-child(3) {
          background-color: $navy;
          position: absolute;
          top: 0;
          mix-blend-mode: multiply;
         
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 32px;
          @include mobile{
            padding: 35px;
          }
          .copy-wrapper {
            width: 189px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 16px;
            @include mobile{
              padding: 20px;
            }
            color: $beige;
            transform: rotate(90deg);
            z-index: 10;
            isolation: isolate;
          }
        }
      }
    }
    .swiper-half {
      width: 50%;
      display: flex;
      height: fit-content;
      gap: 32px;

      @include mobile {
        width: 100%;
        flex-direction: column;

        .nav-arrow-wrapper {
          order: 2;
          transform: rotate(90deg);
          transform-origin: 50px 50px;
        }

      }

      @include tablet {
        width: 100%;
        flex-direction: column;
      }
      @include small-desktop {
        width: unset;
      }
    }
  }
}

.loc-four {
  display: flex;
  gap: 40px;
  padding: 6rem 3rem;
  @include mobile {
    flex-direction: column-reverse;
    padding: 1.5rem;
  }
  @include tablet {
    flex-direction: column-reverse;
    padding: 3rem 1.5rem;
  }
  .left-side {
    width: 25%;
    display: flex;
    gap: 56px;
    flex-direction: column;
    @include mobile {
      width: 100%;
      gap: 24px;
    }
    @include tablet {
      width: 100%;
      gap: 24px;
    }
    .accordion-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;
      .accordion-container {
        width: 100%;
      }
    }
  }
  .map {
    background-image: url("../../../public/images/location/map.png");
    width: 75%;
    height: 700px;
    background-size: cover;
    background-position: 100% 100%;
    @include mobile {
      width: 100%;
      height: 50vh;
      background-position: center;
    }
    @include tablet {
      width: 100%;
      height: 50vh;
    }
  }
}

.loc-five {
  background-color: $pink;
  padding: 120px 3rem;
  @include mobile{
    padding: 1.5rem;
  }
  display: flex;
  flex-direction: column;
  gap: 56px;
  .content-wrapper {
    display: flex;
    gap: 100px;

    @include mobile {
      flex-direction: column;
    }

    .circles-container {
      display: flex;
      flex-direction: column;
      gap: 56px;
      width: 75%;
      max-width: 1200px;
      @include mobile {
        width: 100%;
        gap: 30px;        
      }
      @include tablet {
        width: 100%;
        gap: 30px;
      }
      .circles-wrapper {
        display: flex;
        gap: 56px;
        justify-content: space-between;
        @include mobile {
          flex-wrap: wrap;
          gap: 30px;
        }
        @include tablet {
          flex-wrap: wrap;
          gap: 30px;
        }
        .circle {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $beige;
          width: 218px;
          border-radius: 50%;
          aspect-ratio: 1/1;
          @include mobile {
            width: 45%;
            height: 45%;
            padding: 30px;
          }
        }
      }
    }
    .copy-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 200px;
    }
  }
}
.loc-six {
  padding: 120px 3rem;
  background-color: $forest;
  display: flex;
  @include mobile {
    flex-direction: column;
    padding: 3rem 1.5rem;
  }
  @include tablet {
    flex-direction: column;
    padding: 3rem 2rem;
  }
  h5 {
    color: $beige;
    width: 30%;
    @include mobile {
      width: 100%;
      margin-bottom: 30px;
    }
    @include tablet {
      width: 100%;
      margin-bottom: 30px;
    }
  }
  .swiper-container {
    width: 70%;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
  }
}

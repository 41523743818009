@import "../../scss/mixin.scss";

.home-one {
  padding: 0 3rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    width: 100%;
    border: 0;
  }
  @include mobile {
    padding: 1.5rem 0;
  }
}
.home-two {
  padding: 6rem 3rem;
  overflow-y: hidden;
  display: flex;
  gap: 1.5rem;
  @include mobile {
    flex-direction: column;
    padding: 1.5rem 1.5rem 3rem;
    gap: 3rem;
  }

  @include tablet {
    flex-direction: column;
    padding: 1.5rem 1.5rem 3rem;
    gap: 3rem;
  }
  .copy-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
    h5 {
      padding-bottom: 1.5rem;
      @include mobile {
        padding-bottom: 1rem;
      }
    }
    p {
      width: 75%;
      @include mobile {
        width: 100%;
      }
    }
  }
}

.home-video{
  width: 100%;
  display: block;
  video{
    width: 100%; 
    padding-bottom: 3rem;
    @include mobile {
      padding-bottom: 1.5rem;
    }   
  }
}

.home-three {
  display: flex;
  background-color: $forest;
  padding: 6rem 3rem;
  width: 100%;
  @include mobile {
    flex-direction: column;
    padding: 3rem 1.5rem 0;
  }
  @include tablet {
    flex-direction: column;
    padding: 3rem 1.5rem 0;
  }
  .copy-wrapper {
    display: flex;
    gap: 1.5rem;
    width: 100%;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
    .h1-wrapper {
      width: 50%;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
    }
    h1 {
      width: 100%;
      color: $beige;
    }
    p {
      width: 50%;
      color: $beige;
      @include mobile {
        width: 100%;
      }

      &.reg {
        display: flex;
        justify-content: center;
        align-items: center;
        @include tablet {
          width: 100%;
        }
      }
    }
  }
}

.home-four {
  display: flex;
  flex-direction: column;
  background-color: $forest;
  padding: 6rem 3rem;
  @include mobile {
    padding: 3rem 1.5rem;
  }
  h5.manifesto {
    color: $beige;
    padding-bottom: 2.25rem;
  }
  .card-container {
    display: flex;
    gap: 1.5rem;
    width: 100%;
    padding: 0.75rem 0;
    @include mobile {
      flex-direction: column;
    }
    .card-hero {
      width: 50%;
      @include mobile {
        width: 100%;
      }
    }
  }
}

@import "../../scss/mixin.scss";

.sust-one {
  background-color: $forest;
  padding: 6rem 3rem;
  gap: 1.5rem;
  display: flex;
  @include mobile {
    flex-direction: column;
    padding: 3rem 1.5rem;
  }
  @include tablet {
    flex-direction: row;
  }
  .motion-wrapper {
    color: $beige;
    width: 50%;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
  }
  .copy-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    color: $beige;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
  }
}

.sust-three {
  background-color: $pink;
  padding: 6rem 3rem;
  @include mobile{
    padding: 3rem 1.5rem;
  }
  .icon-wrappers {
    display: flex;
    gap: 3rem;
    justify-content: space-between;
    padding: 5rem 1.5rem 3rem;
    @include mobile {
      flex-direction: column;
      padding: 1.5rem 0;
    }
    @include tablet {
      flex-direction: row;
    }
    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
      img {
        height: 86px;
      }
      p.title {
        text-transform: uppercase;
        color: $olive;
        color: #2a2f1d;
        font-weight: bold;
      }
      p {
        color: $olive;
        color: #2a2f1d;
        text-align: center;
      }
    }
  }
}

.sust-four {
  background-color: $pink;
  display: flex;
  gap: 32px;
  padding: 0rem 3rem 3rem;
  @include mobile {
    flex-direction: column;
    padding: 1.5rem;
  }
  @include tablet {
    flex-direction: column;
  }
  .img-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
    .backimage {
      width: 100%;
      height: 40vw;
      content: " ";
      background-image: url("../../../public/images/building/gallery/7.jpg");
      background-size: cover;
      @include mobile {
        height: 80vw;
      }
      @include tablet {
        height: 80vw;
      }
    }
    .backimage2 {
      width: 100%;
      height: 40vw;
      content: " ";
      background-image: url("../../../public/images/building/gallery/8.jpg");
      background-size: cover;
      @include mobile {
        height: 80vw;
      }
      @include tablet {
        height: 80vw;
      }
    }
  }
}

.sust-five {
  padding: 3rem;
  background-color: $pink;
  display: flex;
  gap: 32px;
  @include mobile {
    flex-direction: column;
    padding: 1.5rem;
  }
  @include tablet {
    flex-direction: column;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 30%;
    h5 {
      padding: 0 0 56px 0;
      @include mobile{
        padding: 0 0 1.5rem 0;
      }
    }

    @include mobile {
      width: 100%;
    }

    @include tablet {
      width: 100%;
    }
    .copy-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 100px 0 0;
      width: 100%;
      @include mobile{
        padding: 0;
      }
      .line {
        content: " ";
        background-color: $olive;
        width: 100%;
        height: 1px;
      }
      .copy {
        display: flex;
        align-items: center;
        padding: 0 0 0 24px;
        p {
          padding: 16px 0;
          text-transform: uppercase;
        }
      }
    }
  }
  .img-wrapper {
    width: 70%;
    background-image: url("../../../public/images/sustainability/2.jpg");
    background-size: cover;
    background-position: 100% 100%;
    content: " ";
    height: calc(100vw / 2);
    @include mobile {
      width: 100%;
      height: 80vw;
    }
    @include tablet {
      width: 100%;
      height: 80vw;
    }
  }
}

.sust-two {
  svg {
    fill: $beige !important;
  }
}
@import '../../scss/mixin.scss';

.accordion {
    display: flex;
    flex-direction: column;
    
    overflow: hidden;
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 24px;
    width: 100%;
    color: white;
    cursor: pointer;    
}

.accordion-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    .copy-wrapper{
        display: flex;
        justify-content: space-between;
    }
}


@import "../../../scss/mixin.scss";

.carousel {
    display: flex;
    align-items: center;
    position: relative; // Position captions correctly
    overflow: hidden;

    .hero-image {
        width: 100%;
        height: 760px;
        background-size: cover;
        position: relative; // Ensure caption is positioned relative to this
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: visible;
        margin-bottom: 40px;
        @include mobile {
            height: calc(100vw / 2);
        }

        &.active {
            margin: 0 20px 40px;
            @include mobile {
                margin: 0 10px 40px;
            }
        }

        &.nextactive {
            margin: 0 0px 40px 20px;
            @include mobile {
                margin: 0 0px 40px 10px;
            }
        }
        .caption {
            position: absolute;
            font-family: "Ridley Grotesk";
            bottom: -50px; // Adjust as needed
            left: 0;
            padding: 10px;
            font-size: 16px;
            text-align: left;
            max-width: 90%;
            color: $black;
            /* z-index: 100; */
            @include mobile {
                font-size: 12px;
                padding: 10px;
                bottom: -40px;
                display: block; // Ensure caption is shown on mobile
            }
        }
    }
}

.nav-pag-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 5%;

    @include mobile {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .pagination-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 20px 0;

        .pagination {
            width: 15px;
            height: 15px;
            @include mobile {
                width: 12px;
                height: 12px;
            }
            background-color: #c1b8a8;
            border-radius: 50%;
            opacity: 1;
            cursor: pointer;

            &.active {
                background-color: $olive;
                opacity: 1;
            }
        }
    }

    .btn-wrapper {
        display: flex;
        gap: 20px;
        padding: 20px 0;
        cursor: pointer;
        justify-content: flex-end;
        padding-right: calc(10% - 50px);

        .next {
            display: flex;
            width: fit-content;
            transform: rotate(90deg);
            @include mobile {
                width: 15px;
            }
        }

        .prev {
            display: flex;
            width: fit-content;
            transform: rotate(-90deg);
            @include mobile {
                width: 15px;
            }
        }
    }
}

@import "../../scss/mixin.scss";

.avail-two {
  display: flex;
  padding: 6rem 3rem;
  background-color: $olive;
  gap: 1.5rem;
  @include mobile {
    flex-direction: column;
    padding: 3rem 1.5rem;
  }

  @include tablet {
    flex-direction: column;
    padding: 3rem 1.5rem;
  }

  .table {
    display: flex;
    width: 75%;
    flex-direction: column;
    gap: 1.5rem;
    @include mobile {
      width: 100%;
      padding-top: 1.5rem;
    }

    @include tablet {
      width: 100%;
      padding-top: 1.5rem;
    }

    .row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 1.5rem;

      &:first-child {
        .row:first-child {
          width: 50%;
        }
        .row:last-child {
          display: flex;
          justify-content: flex-end;
        }
      }
      .cell {
        color: $beige;
        width: 12.5%;
        @include mobile {
          width: 25%;
        }
        &:first-child {
          width: 50%;
        }
        &:last-child {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    .anim-row {
      width: 100%;
      background-color: white;
      position: relative;
      content: " ";
      bottom: 0;
    }
    p {
      color: $beige;
    }
  }
}

.avail-three {
  padding: 6rem 3rem;
  @include mobile {
    padding: 1.5rem;
  }
  @include tablet {
    padding: 3rem 1.5rem;
  }
  .btns-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    @include mobile {
      flex-direction: column;
      gap: 1.5rem;
    }
    @include tablet {
      flex-direction: column;
      gap: 1.5rem;
    }

    @include desktop {
      flex-direction: column;
      gap: 1.5rem;
    }

    @include large-desktop {
      flex-direction: row;
      gap: 1.5rem;
    }

    .f-s {
      display: flex;
      cursor: pointer;
      h6 {
        padding: 0.5em 1em;
        transition: 0.3s background-color ease;
        &.active {
          background-color: $pink;
        }
        &:hover {
          background-color: $pink;
        }
        border: 1px solid $pink;
        &:first-child {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        &:last-child {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
    .floor-btns {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      @include mobile {
        flex-direction: column;
      }
      .btn-wrapper {
        display: flex;
        gap: 1rem;
        @include mobile {
          gap: 0.5rem;
        }
        h6 {
          border-radius: 50%;
          border: 1px solid $pink;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          transition: 0.3s background-color ease;
          cursor: pointer;
          &.active {
            background-color: $pink;
          }
          &:hover {
            background-color: $pink;
          }
        }
      }
    }
  }
  .content {
    display: flex;
    padding: 3rem 0;
    justify-content: space-between;
    gap: 10%;
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
    .text {
      width: 20%;
      position: relative;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
      .floorplan-text {
        position: absolute;
        @include mobile {
          position: relative;
        }
        @include tablet {
          position: relative;
        }
      }
    }
    .floorplan {
      width: 80%;
      overflow-x: hidden;
      overflow-y: visible;
      position: relative;
      height: 40vh;
      @include mobile {
        width: 100%;
        /* height: auto; */
        margin-top: 30px;
        height: fit-content;
      }
      img {
        /* height: 100%; */
        position: absolute;
        right: 0;
        max-width: 100%;
        max-height: 100%;
        @include mobile {
          position: relative;
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .north-footer {
    display: flex;
    padding: 3rem 0;
    justify-content: space-between;
    p {
      display: flex;
      text-align: right;
    }
  }
}

.avail-four {
  display: flex;
  gap: 1.5rem;
  padding: 3rem;
  @include mobile {
    flex-direction: column;
    padding: 1.5rem;
  }
  .motion-wrapper {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }
  p {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }
}

.avail-five {
  padding: 3rem;
  @include mobile {
    padding: 0 1.5rem;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
}

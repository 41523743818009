@import "../../scss/mixin.scss";

.mobile-nav-wrapper {
  display: none;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 99;
  background-color: $military;

  @include mobile {
    display: flex;
    top: 0;
  }

  @include tablet {
    display: flex;
    top: 0;
  }

  &.active {
    .mobnav-header {
      background-color: $military;
      svg {
        fill: $beige !important;
        path {
          fill: #ded5c4 !important;
        }
      }
    }
  }

  .mobnav-header {
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    background-color: $beige;
      &.is-safari {
        background-color: #d7cebb;
    }
    color: white;
    align-items: center;
    height: 60px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .MobNavClose {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .navopened,
      .navclosed {
        position: absolute;
        top: 10px; // Adjust this if needed
        right: 30px; // Adjust this if needed
        transform: scale(0.8);
      }
    }
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: $military;
    height: calc(100vh - 60px);

    .group-links {
      display: flex;
      flex-direction: column;
      height: 300px;
      justify-content: space-around;

      a {
        padding-left: 30px;
        color: $beige;
        text-decoration: none;

        h4 {
          display: inline-block;
          padding: 5px 10px;
        }

        &.actice-circle {
          h4 {
            background-color: $green;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

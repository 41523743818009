@import "../../../scss/mixin.scss";

.caro-wrapper {
  display: flex;
  justify-content: space-between;
  .carousel {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: 90vh;
    width: 75%;
    .hero-image {
      width: 100%;
      height: 760px;
      background-size: cover;
    }
  }

  .nav-pag-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: flex-end;
    width: 25%;
    padding-right: 2rem;

    .pagination-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      padding: 20px 0;
      .pagination {
        width: 15px;
        height: 15px;
        @include mobile{
          width: 12px;
          height: 12px;
        }      
        color: #7e9694;
        background-color: #7e9694;
        border-radius: 50%;
        opacity: 0.4;
        cursor: pointer;
        &.active {
          opacity: 1;
        }
      }
    }
    .btn-wrapper {
      display: flex;
      gap: 20px;
      padding: 20px 0;
      cursor: pointer;
      justify-content: flex-end;
      padding-right: calc(10% - 50px);
      flex-direction: column;
      .next {
        color: beige;
        display: flex;
        width: fit-content;
        transform: rotate(90deg);
      }
      .prev {
        display: flex;
        width: fit-content;
        transform: rotate(-90deg);
      }
    }
  }
}

.swiper-container {
  position: relative;
  width: 100%;
  height: 60vh;
  &.hor {
    @include mobile {
      height: 80vw;
    }
    @include tablet {
      height: 50vh;
    }
  
    @include desktop {
      height: 60vh;
    }
  
    @include large-desktop {
      height: 70vh;
    }

    .gallSwiper {
      width: 100%;
      height: 100%;
      --swiper-pagination-color: #7e9694;
      --swiper-pagination-left: auto;
      --swiper-pagination-right: 15px;
      --swiper-pagination-bottom: 15px;
      --swiper-pagination-top: auto;
      --swiper-pagination-fraction-color: inherit;
      --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
      --swiper-pagination-progressbar-size: 4px;
      --swiper-pagination-bullet-size: 15px;
      --swiper-pagination-bullet-width: 15px;
      --swiper-pagination-bullet-height: 15px;
      --swiper-pagination-bullet-inactive-color: #7e9694;
      --swiper-pagination-bullet-inactive-opacity: 0.6;
      --swiper-pagination-bullet-opacity: 1;
      --swiper-pagination-bullet-horizontal-gap: 4px;
      --swiper-pagination-bullet-vertical-gap: 6px;
      padding-bottom: 60px; // Space for pagination
  
      .slide {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
  
        .slide-image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
        }
      }
  
      .swiper-slide {
        display: flex;
        flex-direction: column;
        /* justify-content: flex-start; */
        .caption {
          width: 100%;
          font-family: "Ridley Grotesk";
          margin-top: 20px;
          color: white;
        }
      }
  
    }
  
    .swiper-controls {
      position: relative;
      bottom: -40px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .swiper-nav {
        display: flex;
        width: 90px;
        display: flex;
        justify-content: space-between;
        align-self: flex-end;
        margin-left: auto;
        .swiper-button-prev,
        .swiper-button-next {
          position: relative;
          width: 30px;
          height: 30px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          z-index: 10;
        }
  
        .swiper-button-prev {
          left: 10px;
          transform: rotate(-180deg) translateY(0%);
          &::after {
            display: none;
          }
        }
  
        .swiper-button-next {
          right: 10px;
          transform: rotate(0) translateY(0);
          &::after {
            display: none;
          }
        }
      }
      .swiper-pagination {
        width: fit-content;
        margin-left: auto;
        margin-right: 0;
        display: flex;
        justify-content: center; 
        align-items: center;
        .swiper-pagination-bullet {
          background-color: rgba(#7e9694, 0.46); 
          opacity: 1; 
        }
  
        .swiper-pagination-bullet-active {
          background-color: #7e9694; 
        }
      }
  
    }


  }
}

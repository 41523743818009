@import "./mixin.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    max-width: 100%;
}

body {
    background-color: $beige;

    @include mobile {
        &.is-safari {
            background-color: #d7cebb;
            .mobnav-header {
                background-color: #d7cebb;
                svg {
                    rect:nth-child(1) {
                        fill: #d7cebb;
                    }
                }
            }
        }
    }
}

.card-wrapper {
    padding: 3rem;
    width: 100%;
    display: flex;
    gap: 3rem;
    @include mobile {
        padding: 1.5rem;
        flex-direction: column;
        gap: 1.5rem;
    }
    .card-hero {
        width: 50%;
        @include mobile {
            width: 100%;
        }
    }
}

.motion-wrapper {
    overflow-y: hidden;
}

.contain {
    max-width: min(100%, 2000px);
    width: 100%;
}

.contain-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.green-back {
    background-color: $forest;
}
.olive-back {
    background-color: $olive;
}
.pink-back {
    background-color: $pink;
}
.beige-back {
    background-color: #dfd8ca;
}

.bayham {
    padding-top: 0;
    @include mobile {
        padding-top: 60px;
    }
}

a {
    color: black;
    text-decoration: none;
}

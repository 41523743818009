@import '../../scss/mixin.scss';

.card-hero{
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    background-size: cover;
    background-position: center;
    
    h3{
        width: 95%;
        max-width: 600px;
        text-wrap: balance;
        padding: 0 0 1.5rem;
        @include mobile{
            width: 100%;
        }
    }
    .border{
        width: 100%;
        content: " ";
        height: 2px;
    }
    a{
        text-decoration: none;
    }
    .card-bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .link-wrapper{
            display: flex;
            align-items: center;
            gap: 1.5rem;
            padding: 1.5rem 0;
            @include mobile{
                gap: 0.5rem;                
            }
            .number{
                width: fit-content;
                border-radius: 50%;                
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .arrow{
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;                        
        }
    }
}
@import "../../../scss/mixin.scss";

#station {
    width: 100%;
    height: 600px;
    @include mobile {
        height: 400px;
    }
    .swiper-slide {
        .connections{
            display: flex;
            gap: 10px;
            .connection{
                background-color: white;
                height: 48px;
                width: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;

            }
        }
        p.station {
            padding-bottom: 16px;
        }
        p.connect {
            padding-bottom: 16px;
        }
        display: flex;
        flex-direction: column;
        gap: 48px;
        .dist-wrapper {
            display: flex;
            gap: 32px;
            width: 75%;
            .dist {
                width: 100%;
                position: relative;
                .anim {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    width: 100%;
                    h3 {
                        text-align: center;
                        width: 50%;
                        color: $black;
                    }
                    h5 {
                        width: 50%;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-transform: uppercase;
                        color: #DFD8CA;
                        padding: 16px 0;
                        top: 0;
                        background-image: linear-gradient(
                            to bottom,
                            $forest,
                            $forest
                        );
                        background-repeat: no-repeat;
                        background-position: 0 100%;

                        display: inline-block;
                    }
                }
                .border-wrapper {
                    position: relative;
                    height: 20px;
                }
                .border {
                    width: 100%;
                    position: absolute;
                    background-color: $forest;
                    bottom: 0;
                }
            }
        }
    }
}
.nav-arrow-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 8px;

    .next,
    .prev {
        display: flex;
        justify-content: center;
        border-radius: 50%;
        align-items: center;
        height: 50px;
        width: 50px;
        border: 2px solid $pink;
        cursor: pointer;
        transition: all ease 0.3s;
        &:hover {
            background-color: $pink;
        }
    }
    .next {
        transform: scaleY(-1);
    }
}

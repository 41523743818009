@import "../../../scss/mixin.scss";

#icon-swiper {
  background-color: $forest;
  width: 100%;
  padding-bottom: 3rem;

  &.swiper-container{
    height: fit-content;
  }

  .swiper-slide {
    display: flex;

    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 3rem;

    @include mobile{
      padding: 0 0 6rem;
      width: 90%;
    }
    img {
      height: 75px;
      @include mobile{
        height: 125px;
      }
    }
    p {
      color: $beige;
    }
    p.title {
      text-transform: uppercase;
    }
    p.copy {
      display: flex;
    }
  }
  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 0 3rem;
    .swiper-pagination-bullet {
      background-color: $beige;
    }
    .swiper-pagination-bullet-active {
      background-color: $beige;
      opacity: 1;
    }
  }
  .swiper-button-next-arrow {
    background-image: url("../../../../public/images/arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    content: " ";
    bottom: 3rem;
    right: 3rem;
    width: 20px;
    display: flex;
    align-items: center;
    height: 20px;
    z-index: 10;
    cursor: pointer;
  }
  .swiper-button-prev-arrow {
    transform: scaleX(-1);
    right: 6rem;
    background-image: url("../../../../public/images/arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    content: " ";
    bottom: 3rem;
    width: 20px;
    display: flex;
    align-items: center;
    height: 20px;
    z-index: 10;
    cursor: pointer;    
  }
  .swiper-button-disabled {
    opacity: 0.2;
    cursor: disabled;
  }
}

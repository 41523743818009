@import "../../scss/mixin.scss";

.footer-hero {
  padding: 6rem 3rem 3rem;
  background-color: $beige;
  @include mobile {
    padding: 3rem 1.5rem;
  }
  .downloads {
    display: flex;
    flex-direction: column;
    padding-bottom: 6rem;
    @include mobile {
      padding-bottom: 3rem;
    }
    h5 {
      padding-bottom: 3rem;
      @include mobile {
        padding-bottom: 1.5rem;
      }
    }
    .dl-btns {
      display: flex;
      gap: 1.5rem;
      @include mobile {
        flex-direction: column;
        gap: 1rem;
      }  

      @include tablet {
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 5px;
      }

      .dl-btn {
        width: 33.3333%;
        height: 56px;
        background-color: $forest;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0rem 1rem;
        @include mobile {
          width: 100%;
        }
        @include tablet {
          width: calc(33.3333% - 5px);
        }
        h6 {
          color: $beige;
        }
      }
    }
  }
  .content-wrapper {
    display: flex;
    @include mobile {
      flex-direction: column;
    }

    @include tablet {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .content-container {
      display: flex;
      flex-direction: column;
      width: 25%;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 48%;
      }
      .agent {
            p {
                font-size: 16px;
            }
        }

      @include small-desktop {
        .agent {
            p {
                font-size: 14px;
            }
        }
      }


      .img-wrapper {
        padding: 0 0 3rem;
        height: 112px;
        @include mobile {
          img {
            height: 100%;
          }
          padding: 1.5rem 0;
        }
        &.block {
          @include mobile {
            display: none;
          }
        }

        img {
          max-width: 115px;
        }

      }
      .toppad {
        padding: 1rem 0 3rem;
        @include mobile {
          padding: rem 0 1.5rem;
        }
      }
      .logo-wrapper {
        display: flex;
        gap: 1rem;
        @include mobile {
          display: none;
        }
        .img-wrapper {
          padding: 0;
          background-color: #dfd8ca;
          width: fit-content;
          border-radius: 50%;
          display: flex;
          height: auto;

          padding: 10px;
        }
      }
      .agent {
        padding-bottom: 1.5rem;
        a {
          color: $black;
        }
      }
    }
  }
  .legal-footer {
    display: flex;
    gap: 1.5rem;
    padding-top: 3rem;
    @include mobile {
      padding-top: 10px;
    }
  }
}

/* .contact-details {
    @include tablet {
        display: flex;
        flex-wrap: wrap;
        .content-container {
            width: 40%;
        }
    }
} */


.siren{
  padding: 1rem 0;
  a{
    color: $black;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
}
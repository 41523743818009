@import '../../../scss/mixin.scss';

.sqft-hero{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 25%;
    .sqft-wrapper{
        background: linear-gradient(to bottom, #CC8B86 30%, transparent 30%, transparent 38%, #CC8B86 38%, #CC8B86 70%, transparent 70%, transparent 78%, #CC8B86 78%);
        &:nth-child(2){
            background: linear-gradient(to bottom, $green 30%, transparent 30%, transparent 38%, $green 38%, $green 70%, transparent 70%, transparent 78%, $green 78%);
        }
        &:nth-child(3){
            background: linear-gradient(to bottom, $beige 30%, transparent 30%, transparent 38%, $beige 38%, $beige 70%, transparent 70%, transparent 78%, $beige 78%);
        }
        h1{
            color: $olive;
            font-size: 120px;
            line-height: 85px;
        }
        padding-bottom: 2em;
    }
    @include mobile{
        width: 100%;        
    }
    @include tablet{
        width: 100%;
    }
}
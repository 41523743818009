@import "../../scss/mixin.scss";

.nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $beige;
    width: 100%;
    padding: 0.75rem 3rem;
    @include mobile {
        padding: 0.75rem 1.5rem;
        display: none;
        &.is-safari {
            background-color: #d7cebb;
        }
    }

    @include tablet {
        display: none;
        justify-content: space-between;
        & > a {
            width: 30%;
        }

        .nav-links {
            width: 70%;
            /* gap: 1.5rem !important; */
            display: flex;
            justify-content: space-between;
        }
    }

    img {
        cursor: pointer;
    }
    z-index: 10;
    .nav-links {
        display: flex;
        gap: 3rem;
        a {
            color: $forest;
            text-decoration: none;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            gap: 10px;
            overflow: hidden;
            h4 {
                font-size: 16px;
                color: #2a2f1d;
                font-weight: bold;
                font-family: "Ridley Grotesk";
                @include tablet {
                    font-size: 14px;
                }
            }
            .active-circle {
                background-color: #2a2f1d;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                content: " ";
            }
        }
    }
}
